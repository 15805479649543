import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: theme.palette.primary.dark,
            borderRadius: '25px',
            boxShadow: 'none',
            color: '#FFFFFF',
            fontFamily: 'MuseoSans, sans-serif',
            fontSize: '14.89px',
            fontWeight: 900,
            letterSpacing: '2.5px',
            maxWidth: '348.5px',
            maxHeight: '39.45px',
            minWidth: '235.5px',
            minHeight: '34.7px',
            '& #icon': { marginLeft: '81.15px' },
            '&:active': {
                backgroundColor: '#A5DCD7',
                boxShadow: 'none',
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                boxShadow: 'none',
            },
            [theme.breakpoints.down(1350)]: {
                '& #icon': { marginLeft: '0px' },
            },
            [theme.breakpoints.down(1000)]: {
                fontSize: '15.91px',
                letterSpacing: '2px',
            },
            [theme.breakpoints.down(660)]: {
                fontSize: '8px',
                letterSpacing: '1.2px',
                minHeight: '25px',
                maxHeight: '25px',
                minWidth: '100px',
                maxWidth: '175px',
            },
            [theme.breakpoints.down(480)]: {
                fontSize: '9px',
                minWidth: '155px',
                maxWidth: '200px',
            },
            [theme.breakpoints.down(400)]: {
                fontSize: '8px',
                letterSpacing: '0.5px',
                minWidth: '90px',
                maxWidth: '200px',
                minHeight: '30px',
            },
        },
    });
