import { Theme, createStyles } from '@material-ui/core';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            fontFamily: 'MuseoSans, sans-serif',
            width: '100vw',
            background: '#3D4274',
            position: 'relative',
            zIndex: theme.zIndex.modal,
            '& #footer-content': {
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                maxWidth: '1400px',
                margin: 'auto',
                justifyContent: 'space-between',
                paddingBottom: '66px',
            },
            [theme.breakpoints.down(1500)]: {
                '& #footer-content': {
                    maxWidth: '90%',
                },
            },
        },
        terms: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            rowGap: '16px',
            '& .t': {
                color: '#FEFEFE',
                fontSize: '20.7px',
                fontWeight: 700,
                letterSpacing: '1px',
                textDecoration: 'none',
                lineHeight: '30px',
                '&:hover': { color: theme.palette.primary.dark },
                '&:focus': { color: theme.palette.primary.dark },
            },
            [theme.breakpoints.down(1050)]: {
                '& .t': {
                    lineHeight: '27px',
                    fontSize: '17px',
                },
            },
            [theme.breakpoints.down(670)]: {
                '& .t': {
                    lineHeight: '25px',
                    fontSize: '10.99px',
                    letterSpacing: '0.57px',
                },
            },
        },
        termsFirst: {
            gridColumnStart: 2,
            gridRowStart: 1,
            [theme.breakpoints.down(900)]: {
                gridColumnStart: 1,
                gridRowStart: 2,
            },
        },
        termsSecond: {
            gridColumnStart: 3,
            gridRowStart: 1,
            [theme.breakpoints.down(900)]: {
                gridColumnStart: 2,
                gridRowStart: 2,
            },
            [theme.breakpoints.down(500)]: {
                gridColumnStart: 1,
                gridRowStart: 3,
            },
        },
        button: {
            gridColumnStart: 1,
            gridRowStart: 1,
            maxWidth: '348.5px',
            maxHeight: '40px',
            alignSelf: 'center',
            [theme.breakpoints.down(1320)]: {
                '& #cuencaButton': {
                    fontSize: '12px',
                },
                maxWidth: '270px',
            },
            [theme.breakpoints.down(660)]: {
                '& #cuencaButton': {
                    fontSize: '6px',
                },
                '& #icon': {
                    fontSize: '8px',
                },
                maxWidth: '270px',
            },
        },
        storeIcons: {
            gridColumnStart: 2,
            gridRowStart: 1,
            alignSelf: 'center',
            '& a': { display: 'inline-block' },
            '& img': { margin: 0 },
            '& a:nth-child(2)': {
                marginLeft: '15px',
            },
            '& a:nth-child(3)': {
                marginLeft: '15px',
            },
            [theme.breakpoints.down(1400)]: {
                '& a:nth-child(2)': {
                    marginLeft: '5px',
                },
                '& a:nth-child(3)': {
                    marginLeft: '5px',
                },
            },
            [theme.breakpoints.down(900)]: {
                gridColumnStart: 1,
                gridRowStart: 2,
            },
            [theme.breakpoints.down(750)]: {
                '& a:nth-child(2)': {
                    marginLeft: '5px',
                },
                '& a:nth-child(3)': {
                    marginLeft: '5px',
                },
            },
            [theme.breakpoints.down(660)]: {
                '& img': { maxWidth: '57px', height: '100%' },
            },
            [theme.breakpoints.down(500)]: {
                gridColumnStart: 1,
                gridRowStart: 2,
            },
            [theme.breakpoints.down(400)]: {
                '& img': { maxWidth: '50px' },
            },
        },
        social: {
            height: 'auto',
            minWidth: '191px',
            gridColumnStart: 3,
            gridRowStart: 1,
            [theme.breakpoints.down(900)]: {
                gridColumnStart: 2,
                gridRowStart: 2,
            },
            [theme.breakpoints.down(660)]: {
                gridRowGap: '47px',
            },
            [theme.breakpoints.down(501)]: {
                minWidth: '120px',
            },
            [theme.breakpoints.down(500)]: {
                gridColumnStart: 1,
                gridRowStart: 3,
            },
        },
        logos: {
            height: '20px',
            width: '100%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flexDirection: 'row',
        },
        buttonsContainer: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridTemplateRows: '1fr',
            gridColumnGap: '100px',
            justifyItems: 'start',
            alignItems: 'center',
            marginTop: '60px',
            width: '100%',
            [theme.breakpoints.down(900)]: {
                gridTemplateRows: '1fr 1fr',
                gridTemplateColumns: '1fr 1fr',
                gridRowGap: '25px',
                gridColumnGap: '20px',
            },
            [theme.breakpoints.down(500)]: {
                gridTemplateRows: '1fr 1fr 1fr',
                gridTemplateColumns: '1fr',
                gridRowGap: '25px',
                paddingBottom: '25px',
                borderBottom: '1px solid #FEFEFE55',
            },
        },
        infoAndLinksContainer: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridTemplateRows: '1fr',
            gridColumnGap: '100px',
            justifyItems: 'start',
            alignItems: 'start',
            marginTop: '60px',
            width: '100%',
            '& p:last-child': {
                marginBottom: 0,
            },
            [theme.breakpoints.down(900)]: {
                gridTemplateRows: '1fr 1fr',
                gridTemplateColumns: '1fr 1fr',
                gridRowGap: '0px',
                gridColumnGap: '20px',
            },
            [theme.breakpoints.down(500)]: {
                gridTemplateRows: '1fr 1fr 0.5fr',
                gridTemplateColumns: '1fr',
                gridRowGap: '15px',
                gridColumnGap: '0px',
                marginTop: '30px',
            },
        },
        condusef: {
            gridColumnStart: 1,
            gridRowStart: 1,
            color: '#FEFEFE',
            fontWeight: 300,
            fontSize: '20.7px',
            lineHeight: '24.84px',
            letterSpacing: '1px',
            '& .bold': { fontWeight: 600 },
            '& a': {
                textDecoration: 'none',
                color: '#FEFEFE',
            },
            [theme.breakpoints.down(1050)]: {
                lineHeight: '27px',
                fontSize: '17px',
            },
            [theme.breakpoints.down(670)]: {
                lineHeight: '25px',
                fontSize: '10.99px',
                letterSpacing: '0.57px',
            },
            [theme.breakpoints.down(500)]: {
                marginBottom: '25px',
                paddingBottom: '25px',
                borderBottom: '1px solid #FEFEFE55',
            },
        },
        footerFooter: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridTemplateRows: '1fr',
            gridColumnGap: '100px',
            justifyItems: 'start',
            alignItems: 'start',
            marginTop: '35px',
            width: '100%',
            [theme.breakpoints.down(900)]: {
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: '1fr',
                gridColumnGap: '20px',
            },
            [theme.breakpoints.down(500)]: {
                gridTemplateColumns: '1fr',
                gridTemplateRows: '1fr 1fr',
                gridRowGap: '20px',
                gridColumnGap: 0,
            },
        },
        copy: {
            gridColumnStart: 1,
            gridRowStart: 1,
            fontWeight: 600,
            color: '#FFFFFF',
            fontSize: '13.8px',
            lineHeight: '16.56px',
            letterSpacing: '2px',
            [theme.breakpoints.down(660)]: {
                fontSize: '9.8px',
            },
            [theme.breakpoints.down(500)]: {
                gridRowStart: 2,
            },
        },
        commission: {
            gridColumnStart: 3,
            gridRowStart: 1,
            '& .t': {
                color: '#FF8136',
                fontSize: '20.7px',
                fontWeight: 700,
                letterSpacing: '1px',
                textDecoration: 'none',
                lineHeight: '30px',
                '&:hover': { color: theme.palette.primary.dark },
                '&:focus': { color: theme.palette.primary.dark },
            },
            [theme.breakpoints.down(900)]: {
                gridColumnStart: 2,
            },
            [theme.breakpoints.down(1050)]: {
                '& .t': {
                    lineHeight: '27px',
                    fontSize: '17px',
                },
            },
            [theme.breakpoints.down(670)]: {
                '& .t': {
                    lineHeight: '25px',
                    fontSize: '10.99px',
                    letterSpacing: '0.57px',
                },
            },
            [theme.breakpoints.down(500)]: {
                gridColumnStart: 1,
                paddingBottom: '25px',
                borderBottom: '1px solid #FEFEFE55',
            },
        },
    });
