// React imports
import React from 'react';

// UI – style imports
import clsx from 'clsx';
import { WithStyles, withStyles } from '@material-ui/core';
import GitHubIcon from '@material-ui/icons/GitHub';
import IconButton from '@material-ui/core/IconButton';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import SvgIcon from '@material-ui/core/SvgIcon';

// local imports
import { styles } from './SocialIcon.style';

type SocialIconProps = {
    social: string;
    link: string;
    size: string;
    className?: string;
} & typeof defaultProps;

const defaultProps = {
    social: 'github',
    link: 'https://www.github.com/cuenca-mx/',
    size: 'small',
};

const SIZES = {
    smol: 15,
    small: 21,
    medium: 24,
    large: 30,
};

const SOCIAL_ICONS = {
    facebook: size => (
        <SvgIcon style={{ fontSize: SIZES[size] }} viewBox="10 5 24 24">
            <path d="M20.89,17H18.41v9.27H14.73V17H13V13.72h1.75V11.6c0-1.52.69-3.88,3.71-3.88h2.73v3.19h-2c-.32,0-.78.16-.78.89v1.92h2.8Z" />
        </SvgIcon>
    ),
    github: size => <GitHubIcon style={{ fontSize: SIZES[size] }} />,
    instagram: size => <InstagramIcon style={{ fontSize: SIZES[size] }} />,
    tiktok: size => (
        <SvgIcon style={{ fontSize: SIZES[size] }} viewBox="5 4 24 24">
            <path d="M24.09,14.83a3.39,3.39,0,0,1-.45,0,4.92,4.92,0,0,1-4.12-2.22V20.2a5.6,5.6,0,1,1-5.59-5.59h.34v2.76c-.11,0-.22,0-.34,0a2.86,2.86,0,1,0,0,5.71,2.93,2.93,0,0,0,3-2.82l0-12.86h2.63a4.92,4.92,0,0,0,4.53,4.39v3.06" />
        </SvgIcon>
    ),
    twitter: size => <TwitterIcon style={{ fontSize: SIZES[size] }} />,
};

function SocialIcon(props: SocialIconProps & WithStyles<typeof styles>) {
    const { classes, className, social, link, size } = props;

    return (
        <IconButton
            className={clsx(classes.root, className)}
            href={link}
            target="_blank"
            aria-label={`${social} logo icon`}
        >
            {SOCIAL_ICONS[social](size)}
        </IconButton>
    );
}
SocialIcon.defaultProps = defaultProps;

export default withStyles(styles)(SocialIcon);
