// react imports
import React from 'react';

// theme imports to wrapp component in Theme
import { ThemeProvider } from '@material-ui/core/styles';
import theme from 'theme';

type WithThemeProps = {
    children: React.ReactNode;
};

const WithTheme: React.FC<WithThemeProps> = ({ children }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;

export default WithTheme;
