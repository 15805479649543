import { Theme, createStyles } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginBottom: '15px',
            maxHeight: '18.5px',
            maxWidth: '18px',
            minHeight: '15.3px',
            minWidth: '8px',
            padding: '0',
            color: theme.palette.primary.dark,
            '&:hover': {
                color: theme.palette.primary.light,
                boxShadow: 'none',
            },
            '&:active': {
                color: '#A5DCD7',
                boxShadow: 'none',
            },
        },
    });
