import { createMuiTheme } from '@material-ui/core/styles';

const palette = {
    primary: {
        light: '#00B7B0', // Verde Cuenca Digital
        main: '#3D4975', // Azul Cuenca Digital
        dark: '#FF8136', // Naranja Cuenca Digital
        section: '#88C3EA', // Azul Tarjetas Cuenca (secciones de travesia)
        gray: '#B2B6C8', // Gris
    },
    secondary: {
        light: '#F7B4BF', // Rosa Cuenca Digital
        main: '#F1D9A1', // Amarillo Cuenca Digital
        dark: '#89BCE7', // Cyan Cuenca Digital
    },
};

const breakpoints = {
    values: {
        mobile: 700,
        tablet: 768,
        desktop: 1400,
    },
};

declare module '@material-ui/core/styles/createBreakpoints' {
    interface BreakpointOverrides {
        xs: false;
        sm: false;
        md: false;
        lg: false;
        xl: false;
        mobile: true;
        tablet: true;
        desktop: true;
    }
}

export default createMuiTheme({ palette, breakpoints });
