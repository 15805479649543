// React imports
import React from 'react';

// UI – style imports
import clsx from 'clsx';
import { WithStyles, withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

// local imports
import { styles } from './Button.style';

type CuencaButtonProps = {
    text: string;
    whatsapp: boolean;
    link?: string;
    onClick?: () => void;
    className?: string;
} & typeof defaultProps;

const defaultProps = {
    text: 'Boton',
    whatsapp: false,
    onClick: () => console.log('Button clicked!'),
};

function CuencaButton(props: CuencaButtonProps & WithStyles<typeof styles>) {
    const { classes, className, text, whatsapp, link, onClick } = props;

    return (
        <Button
            id="cuencaButton"
            className={clsx(classes.root, className)}
            color="primary"
            variant="contained"
            href={link ? link : null}
            target="_blank"
            endIcon={whatsapp ? <WhatsAppIcon id="icon" /> : null}
            onClick={onClick}
        >
            {text.toUpperCase()}
        </Button>
    );
}
CuencaButton.defaultProps = defaultProps;

export default withStyles(styles)(CuencaButton);
