// React imports
import React from 'react';

// gatsby improts
import { Link } from 'gatsby';

// UI – style imports
import { WithStyles, withStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

// local imports
import { styles } from './Footer.style';
import Button from 'components/Button';
import SocialIcon from 'components/SocialIcon';
import appStore from 'images/logos/app-store.svg';
import playStore from 'images/logos/play-store.svg';
import galleryStore from 'images/logos/gallery-store.svg';

function Footer(props: WithStyles<typeof styles>) {
    const { classes } = props;
    const iconSize = useMediaQuery('(min-width:1050px)') ? 'small' : 'smol';

    return (
        <Box className={classes.root}>
            <Box id="footer-content">
                <Box className={classes.buttonsContainer}>
                    <Box className={classes.button}>
                        <Button whatsapp text="Centro de soporte" link="https://wa.me/525513285364" />
                    </Box>

                    <Box className={classes.storeIcons}>
                        <a
                            href="https://apps.apple.com/mx/app/cuenca-alternativa-a-un-banco/id1447995379"
                            target="_blank"
                        >
                            <img src={appStore} alt="Apple App Store link" />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=com.cuenca.cuenca_mobile"
                            target="_blank"
                        >
                            <img src={playStore} alt="Google Play Store link" />
                        </a>
                        <a href="https://appgallery.huawei.com/#/app/C103010973" target="_blank">
                            <img src={galleryStore} alt="Huawei App Gallery link" />
                        </a>
                    </Box>

                    <Box className={classes.social}>
                        <Box className={classes.logos}>
                            <SocialIcon
                                size={iconSize}
                                social="facebook"
                                link="https://www.facebook.com/cuencamexico"
                            />
                            <SocialIcon size={iconSize} social="instagram" link="https://www.instagram.com/cuenca" />
                            <SocialIcon size={iconSize} social="twitter" link="https://www.twitter.com/cuenca_mx" />
                            <SocialIcon size={iconSize} social="tiktok" link="https://vm.tiktok.com/JdbEhaU" />
                            <SocialIcon size={iconSize} social="github" link="https://www.github.com/cuenca-mx" />
                        </Box>
                    </Box>
                </Box>

                <Box className={classes.infoAndLinksContainer}>
                    <Box className={classes.condusef}>
                        <p className="bold">Condusef</p>
                        <p>
                            Comisión Nacional para la Protección y Defensa de los Usuarios de los Servicios Financieros
                        </p>
                        <p>
                            <span className="bold">W: </span>
                            <a target="_blank" href="https://www.condusef.gob.mx/">
                                www.condusef.gob.mx
                            </a>
                        </p>
                        <p>
                            <span className="bold">T: </span>
                            <a target="_blank" href="tel:5553400999">
                                55.53.400.999
                            </a>
                        </p>
                    </Box>

                    <Box className={clsx(classes.terms, classes.termsFirst)}>
                        <Link to="/aviso-privacidad" className="t">
                            Aviso de privacidad
                        </Link>
                        <a target="_blank" href="https://blog.cuenca.com/" className="t">
                            Blog
                        </a>
                        <Link to="/buro" className="t">
                            Buró de Entidades Financieras
                        </Link>
                        <Link to="/contrato-adhesion" className="t">
                            Contrato de adhesión
                        </Link>
                        <Link to="/estados-financieros" className="t">
                            Estados financieros
                        </Link>
                        <Link to="/faq" className="t">
                            Preguntas frecuentes
                        </Link>
                        <Link to="/aviso-modificacion" className="t">
                            Aviso de modificación contrato adhesión
                        </Link>
                    </Box>

                    <Box className={clsx(classes.terms, classes.termsSecond)}>
                        <Link to="/controversias-y-aclaraciones" className="t">
                            Resolución de Controversias y Aclaraciones
                        </Link>
                        <Link to="/terminos-y-condiciones" className="t">
                            Términos y condiciones
                        </Link>
                        <a target="_blank" href="https://careers.cuenca.com/" className="t">
                            Únete al equipo
                        </a>
                        <Link to="/une" className="t">
                            UNE
                        </Link>
                    </Box>
                </Box>

                <Box className={classes.footerFooter}>
                    <Box className={classes.copy}>
                        <p>©CUENCA, {new Date().getFullYear()}</p>
                    </Box>

                    <Box className={classes.commission}>
                        <Link to="/comisiones" className="t">
                            Consulta los costos y comisiones de nuestros productos
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default withStyles(styles)(Footer);
